import IGeneric from "../interfaces/IGeneric";

import "../styles/SliderInput.css";
import Label from "./Label";

import { handleKeyDown, handleKeyUp, validator } from "../utils/validators";
import { getPrefix, getSuffix } from "../utils/mutate";

import React, { useEffect, useState } from "react";

export interface ChildComponentProp {
    validate: () => boolean;
    [key: string]: any;
}
export const SliderInput: React.FC = (props: IGeneric) => {
    const { type, attributes, elementId, onStateChange, onEnter, validations, onError, error, containerRef, inputRef, tabIndex } = props;

    const { minValue, maxValue } = validations;

    const { value, label, tooltip, stepIncrement, noWrap } = attributes;

    const [sliderValue, setSliderValue] = useState<number | undefined>(value);
    const [textBoxValue, setTextBoxValue] = useState<number | undefined>(value);

    const validate = (value: number | undefined, show: boolean) => {
        const validationMessage = validator(
            value,
            "number",
            validations,
            attributes,
        );

        if (validationMessage)
            onError({ validationMessage, show });
        else {
            onStateChange(value);
            setSliderValue(value);
            setTextBoxValue(value);
            onError(undefined);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value ? parseFloat(event.target.value) : undefined;
        inputRef.current && (inputRef.current.value = newValue);
        validate(newValue, true);
    }

    const prefix = getPrefix(textBoxValue ? textBoxValue.toString() : "", attributes);

    useEffect(() => {
        validate(sliderValue, false);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={`input-container slider-input ${noWrap}`} id={`${elementId}_container`} ref={containerRef}>
            <div>
                <div>
                    <Label
                        elementId={elementId}
                        content={label}
                        tooltip={tooltip}
                    />
                    <div className="relative">
                        <div className="prefix">{prefix}</div>
                        <input
                            className={`${prefix.length > 0 ? "prefix" : ""} ${error && error.show ? "error" : ""}`}
                            id={`${elementId}-input`}
                            name={`${elementId}-input`}
                            type="number"
                            min={minValue}
                            max={maxValue}
                            step={stepIncrement}
                            defaultValue={textBoxValue}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            onKeyUp={(event) => handleKeyUp(event, onEnter)}
                            key={`${elementId}-input`}
                            ref={inputRef}
                            tabIndex={tabIndex}
                        />
                        <div className="suffix">{getSuffix(textBoxValue ? textBoxValue.toString() : "", attributes)}</div>
                    </div>
                    <input
                        id={elementId}
                        name={elementId}
                        type={type}
                        min={minValue}
                        max={maxValue}
                        value={sliderValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onKeyUp={(event) => handleKeyUp(event, onEnter)}
                        aria-label={label}
                        step={stepIncrement}
                        key={elementId}
                        tabIndex={tabIndex + 0.1}
                    />
                    <p
                        id={`${elementId}_error`}
                        className="error"
                    >
                        {error && error.show && error.validationMessage}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SliderInput;
