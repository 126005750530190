import IGeneric from "../interfaces/IGeneric";
import IMessage from "../interfaces/IMessage";
import IForm from "../interfaces/IForm";
import IFormField from "../interfaces/IFormField";
import IJourneyResponse from "../interfaces/IJourneyResponse";
import IComponent from "../interfaces/IComponent";
import IMessageBubble from "../interfaces/IMessageBubble";
import IHeaders from "../interfaces/IHeaders";
import IComponentList from "../interfaces/IComponentList";

import { getJourneyResponse } from "./JourneyResponse";

import { getFormattedString } from "../utils/mutate";

import { createRef } from "react";
import IMenu from "../interfaces/IMenu";
import IMenuItem from "../interfaces/IMenuItem";

export const getFormComponents = async (
    formData: IGeneric | undefined,
    updateComponentListCallback: (result: IComponent[]) => void,
    updateMenusCallback: (menus: Map<string, IMenu>) => void,
    updateFormHistoryCallback: (result: IForm[], preserve: boolean) => void,
    updateMessagesStateCallback: (result: IMessageBubble[], preserve: boolean) => void,
    addFormHistoryCallback: (result: IForm) => void,
    addNewMessageCallback: (result: IMessageBubble) => void,
    updateHeadersCallback: (result: IHeaders) => void,
    headerState: IHeaders,
    messageBubbles: IMessageBubble[],
    currentForm: IForm | undefined,
    resumeHeader: IGeneric | undefined,
    menuItem: IMenuItem | undefined,
): Promise<IForm> => {

    const chatView = process.env.REACT_APP_CHAT_VIEW === "true";

    const response: IJourneyResponse = await getJourneyResponse(
        updateHeadersCallback,
        headerState,
        [],
        currentForm,
        formData,
        resumeHeader,
        menuItem
    );
    const { form, menus, formHistory } = response;

    const { returnHistory, clearBreadcrumb } = form;

    if (Object.keys(formData ?? {}).length === 0 || (clearBreadcrumb && !chatView) || (returnHistory !== undefined && !returnHistory)) {
        updateFormHistoryCallback([], false);
        updateMessagesStateCallback([], false);
    }
    if (menus) {
        updateMenusCallback(menus);
    }
    if (formHistory !== null) {
        updateFormHistoryCallback(formHistory, returnHistory ?? false);
        updateMessagesStateCallback(extractMessageHistory(formHistory), returnHistory ?? false);
    }
    const components = extractComponents(form.fields);

    updateComponentListCallback(components);
    addFormHistoryCallback(form)

    const displayNewMessage = (message: string, messageNumber: number) => {
        const newMessage: IMessageBubble = {
            userMessage: false,
            bubbleId: `message-bubble-${messageNumber}`,
            messages: [{
                elementId: `message-${messageNumber}`,
                value: message || "",
            }],
            disabled: false,
            messageBubbleRef: createRef<HTMLDivElement>(),
        };

        addNewMessageCallback(newMessage)
        //EventBus.$emit("renderNewMessage", false); // TODO not sure purpose of this
    }

    //if(response.formHistory === null) {
    displayNewMessage(form.text, messageBubbles.filter((messageBubble) => !messageBubble.userMessage).length + 1);
    //}

    return form
};

export const extractComponents = (fields: IFormField[]): IComponent[] => {
    return fields.map((formField) => {
        return buildComponent(formField) as unknown as IComponent;
    }).filter((component) => component);;
}
function extractMessageHistory(formHistory: IForm[]): IMessageBubble[] {
    const messageBubbles: IMessageBubble[] = [];
    if (formHistory !== undefined) {
        let id = 0;
        formHistory.forEach((form) => {
            id += 1;
            let messageBubble: IMessageBubble = {
                actionId: form.actionId,
                userMessage: false,
                bubbleId: `message-bubble-${id}`,
                formName: form.name,
                workflowName: form.workflow,
                messages: [{
                    elementId: `form_${id.toString()}`,
                    value: form.text,
                }],
                responseDate: form.responseDate,
                requestDate: form.requestDate,
                disabled: false,
                messageBubbleRef: createRef<HTMLDivElement>(),
            };
            messageBubbles.push(messageBubble);
            const previousMessages: IMessage[] = [];
            let previousFormData: IGeneric = {};
            form.fields.map((formField) => {
                const component = buildComponent(formField);
                if (component) {
                    const { elementId, title, attributes } = component;
                    const value = attributes?.value;
                    const formattedString = getFormattedString(component);
                    if (elementId && formattedString) {
                        previousMessages.push({
                            elementId,
                            value,
                            displayValue: formattedString,
                            title,
                        });

                        previousFormData = {
                            ...previousFormData,
                            ...{ [elementId]: value },
                        };
                    }
                }
                return formField;
            });
            if (form.submittedMessageText) {
                previousMessages.push({
                    elementId: `submit`,
                    value: form.submittedMessageText,
                    displayValue: form.submittedMessageText,
                });
            }

            previousFormData = {
                ...previousFormData,
                ...{ "submittedMessageText": form.submittedMessageText ?? " " },
            };

            if (previousMessages.length > 0) {
                messageBubble = {
                    actionId: form.actionId,
                    userMessage: true,
                    bubbleId: `user-message-bubble-${id}`,
                    formName: form.name,
                    workflowName: form.workflow,
                    messages: previousMessages,
                    formData: previousFormData,
                    responseDate: form.responseDate,
                    requestDate: form.requestDate,
                    disabled: false,
                    messageBubbleRef: createRef<HTMLDivElement>(),
                };
                messageBubbles.push(messageBubble);
            }
        });
    }
    return messageBubbles;
}

const buildComponent = (formField: IFormField) => {

    const component = IComponentList.find((c: IComponent) => formField.type === c.jsonName);
    if (!component) return;

    const componentOptions: IComponent = {
        ...component,
        elementId: formField.name,
        title: formField.text,
        containerRef: createRef<HTMLDivElement>(),
    }

    componentOptions.attributes = {
        placeholder: formField.attributes?.placeholder,
        label: formField.attributes?.ariaLabel,
        value: formField.attributes?.value,
        width: formField.attributes?.width,
        tooltip: formField.attributes?.tooltip,
        noWrap: formField.attributes?.noWrap ? "nowrap" : "",
    }
    componentOptions.validations = {
        minLength: formField.attributes?.minLength,
        maxLength: formField.attributes?.maxLength,
        pattern: formField.attributes?.pattern,
        required: formField.required,
        requiredMessage: formField.attributes?.requiredMessage,
        validationMessage: formField.attributes?.validationMessage,
    };

    switch (formField.type) {
        case "title": {
            break;
        }
        case "text": {
            componentOptions.attributes.value = formField.attributes?.content;
            componentOptions.attributes.unit = formField.attributes?.unit;
            componentOptions.attributes.locale = formField.attributes?.locale;
            componentOptions.attributes.decimals = formField.attributes?.decimals;
            componentOptions.attributes.currency = formField.attributes?.currency;
            componentOptions.attributes.noWrap = (formField.attributes?.noWrap ?? true) ? "nowrap" : "";
            break;
        }
        case "image": {
            componentOptions.attributes.href = formField.attributes?.href;
            componentOptions.attributes.maxWidth = formField.attributes?.maxWidth;
            componentOptions.attributes.maxHeight = formField.attributes?.maxHeight;
            componentOptions.attributes.unit = formField.attributes?.unit;
            break;
        }
        case "pdf": {
            componentOptions.attributes.href = formField.attributes?.href;
            componentOptions.attributes.fileName = formField.attributes?.fileName;
            break;
        }
        case "input_radio":
        case "input_button_radio": {
            componentOptions.options = formField.options;
            componentOptions.attributes.orientation = formField.attributes?.orientation;
            componentOptions.inputRef = createRef<HTMLInputElement>();
            break;
        }
        case "input_checkbox": {
            componentOptions.options = formField.options;
            componentOptions.attributes.orientation = formField.attributes?.orientation;
            componentOptions.attributes.checkAll = formField.attributes?.checkAll;
            componentOptions.attributes.checkAllLabel = formField.attributes?.checkAllLabel;
            componentOptions.inputRef = createRef<HTMLInputElement>();
            break;
        }
        case "input_submit": {
            componentOptions.title = formField.text;
            componentOptions.isEdit = false;
            componentOptions.inputRef = createRef<HTMLButtonElement>();
            break;
        }
        case "input_phone":
            componentOptions.validations.pattern = componentOptions.validations.pattern ?? "[^0-9.]";
            componentOptions.inputRef = createRef<HTMLInputElement>();
            break;
        case "address_line":
        case "address_city":
        case "address_county":
        case "address_postcode": {
            break;
        }
        case "input_text": {
            componentOptions.type = formField.attributes?.password === true ? "password" : formField.attributes?.textBox === true ? "textarea" : "text";
            componentOptions.attributes.textBoxHeight = formField.attributes?.textBoxHeight;
            componentOptions.attributes.visibility = formField.attributes?.visibility;
            componentOptions.inputRef = component.type === "textarea" ? createRef<HTMLTextAreaElement>() : createRef<HTMLInputElement>();
            break;
        }
        case "input_sso": {
            componentOptions.title = formField.attributes?.title;
            componentOptions.attributes.provider = formField.attributes?.provider;
            break;
        }
        case "input_address": {
            componentOptions.attributes.buildingName = formField.attributes?.buildingName;
            componentOptions.attributes.buildingNumber = formField.attributes?.buildingNumber;
            componentOptions.attributes.street = formField.attributes?.street;
            componentOptions.attributes.town = formField.attributes?.town;
            componentOptions.attributes.county = formField.attributes?.county;
            componentOptions.attributes.country = formField.attributes?.country;
            componentOptions.attributes.subBuildingName = formField.attributes?.subBuildingName;
            componentOptions.attributes.subBuildingNumber = formField.attributes?.subBuildingNumber;
            componentOptions.attributes.postcode = formField.attributes?.postcode;
            componentOptions.attributes.provider = formField.attributes?.provider;
            componentOptions.attributes.apiKey = formField.attributes?.apiKey;
            componentOptions.attributes.lookupType = formField.attributes?.lookupType;
            componentOptions.attributes.countryLimitCode = formField.attributes?.countryLimitCode;
            componentOptions.inputRef = createRef<HTMLInputElement>();
            break;
        }
        case "input_select": {
            componentOptions.options = formField.options;
            componentOptions.attributes.searchable = formField.attributes?.searchable;
            componentOptions.attributes.multiple = formField.attributes?.multiple;
            componentOptions.inputRef = createRef<HTMLSelectElement>();
            break;
        }
        case "input_date": {
            componentOptions.attributes.format = formField.attributes?.format;
            componentOptions.validations.minDate = formField.attributes?.minDate;
            componentOptions.validations.maxDate = formField.attributes?.maxDate;
            componentOptions.inputRef = createRef<HTMLInputElement>();
            break;
        }
        case "input_file": {
            componentOptions.attributes.multiple = formField.attributes?.multiple;
            componentOptions.attributes.fileName = formField.attributes?.fileName;

            componentOptions.validations.maxSize = formField.attributes?.maxSize;
            componentOptions.validations.fileType = formField.attributes?.fileType;
            componentOptions.inputRef = createRef<HTMLInputElement>();
            break;
        }
        case "input_email":
            componentOptions.inputRef = createRef<HTMLInputElement>();
            break;
        case "input_hidden":
            break;
        case "income":
        case "expense":
        case "input_number":
        case "input_money":
        case "amount_slider":
        case "term_slider": {
            componentOptions.attributes.unit = formField.attributes?.unit;
            componentOptions.attributes.locale = formField.attributes?.locale;
            componentOptions.attributes.decimals = formField.attributes?.decimals;
            componentOptions.attributes.currency = formField.attributes?.currency;
            componentOptions.attributes.stepIncrement = formField.attributes?.stepIncrement;

            componentOptions.validations.minValue = formField.attributes?.minValue;
            componentOptions.validations.maxValue = formField.attributes?.maxValue;
            componentOptions.inputRef = createRef<HTMLInputElement>();
            break;
        }
        case "input_tokenex_card": {
            componentOptions.attributes.authenticationKey = formField.attributes?.authenticationKey;
            componentOptions.attributes.timestamp = formField.attributes?.timestamp;
            componentOptions.attributes.tokenExId = formField.attributes?.tokenExId;
            componentOptions.attributes.includeCvv = formField.attributes?.includeCvv;
            componentOptions.attributes.cvvLabel = formField.attributes?.cvvLabel;
            componentOptions.attributes.cvvPlaceholder = formField.attributes?.cvvPlaceholder;
            componentOptions.attributes.includeExpiry = formField.attributes?.includeExpiry;
            componentOptions.attributes.expiryMonthLabel = formField.attributes?.expiryMonthLabel;
            componentOptions.attributes.expiryMonthPlaceholder = formField.attributes?.expiryMonthPlaceholder;
            componentOptions.attributes.expiryYearLabel = formField.attributes?.expiryYearLabel;
            componentOptions.attributes.expiryYearPlaceholder = formField.attributes?.expiryYearPlaceholder;

            componentOptions.inputRef = createRef<HTMLInputElement>();
            break;
        }
        case "output_table": {
            componentOptions.tableColumns = formField.tableColumns;
            componentOptions.tableData = formField.tableData;
            componentOptions.actionColumns = formField.actionColumns;
            componentOptions.attributes.editable = formField.attributes?.editable;
            componentOptions.attributes.addable = formField.attributes?.addable;
            componentOptions.attributes.deleteable = formField.attributes?.deleteable;
            componentOptions.attributes.searchable = formField.attributes?.searchable;
            componentOptions.attributes.noWrap = (formField.attributes?.noWrap ?? true) ? "nowrap" : "";
            break;
        }
        case "output_files":
        case "output_files_download": {
            componentOptions.files = formField.files;
            break;
        }
        case "output_widget": {
            componentOptions.widgets = formField.widgets;
            componentOptions.attributes.label = formField.text;
            break;
        }
    }
    return componentOptions;
}


