import IMenuItem from "../interfaces/IMenuItem";
import IMenuItemProps from "../interfaces/IMenuItemProps";

import Menu from "./Menu";

import React from "react";

export const MenuItem: React.FC<IMenuItemProps> = ({
    title,
    trigger,
    icon,
    reInitialise,
    subMenuItems,
    menuItemCallback,
    id
}) => {
    return (
        <>
            {subMenuItems &&
                <div
                    className="hs-dropdown"
                >
                    <button
                        id={`hs-submenu-${id}-dropdown`}
                        type="button"
                        className="hs-dropdown-toggle"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        aria-label={title}
                    >
                        {title}
                        <svg
                            className="sub-menu-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="m6 9 6 6 6-6" />
                        </svg>
                    </button>
                    <div
                        className="hs-dropdown-menu transition-[opacity,margin] hs-dropdown-open:opacity-100 opacity-0 hidden before:absolute"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby={`hs-submenu-${id}-dropdown`}
                    >
                        <div className="sub-menu-items-1">
                            <div className="sub-menu-items-2">
                                {subMenuItems.map((menuItem: IMenuItem, index: number) =>
                                    <button
                                        type="button"
                                        className="sub-menu-item"
                                        key={`${id}_${index}`}
                                        onClick={() => menuItemCallback({ title: menuItem.title, trigger: menuItem.trigger, icon: menuItem.icon, reInitialise: menuItem.reInitialise })}
                                    >
                                        {menuItem.title}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!subMenuItems &&
                <button
                    className="menu-item"
                    onClick={() => menuItemCallback({ title, trigger, icon, reInitialise })}
                >
                    {title}
                </button>
            }
        </>
    );
};

export default MenuItem;
