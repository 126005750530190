import ILabelProps from "../interfaces/ILabelProps";

import React from "react";

export const Label: React.FC<ILabelProps> = ({
    elementId,
    content,
    tooltip,
}) => {
    return (
        <label
            className="label"
            htmlFor={elementId}
        >
            <span>{content}</span>
            {tooltip &&
                <div className="tooltip hs-tooltip">
                    <button type="button" className="hs-tooltip-toggle">
                        <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible" role="tooltip">
                            {tooltip}
                        </span>
                    </button>
                </div>
            }
        </label>
    );
};

export default Label;
