import IGeneric  from "../interfaces/IGeneric";

import "../styles/SubmitButton.css";

import React from "react";

export const SubmitButton: React.FC = (props: IGeneric) => {
    const { title, elementId, onSubmit, isEdit, onCancel, previousForm, previousFormName, previousWorkflowName, inputRef, tabIndex } = props;

    const handleMouseSubmit = (event: React.MouseEvent) => {
        event.preventDefault();
        if (event.button === 0)
            onSubmit();
    };

    const handleMouseCancel = (event: React.MouseEvent) => {
        event.preventDefault();
        if (event.button === 0)
            onCancel(previousForm, previousFormName, previousWorkflowName);
    };

    const handleKeySubmit = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            event.stopPropagation();
            event.preventDefault()
            onSubmit();
        }
    }

    const handleKeyCancel = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            event.stopPropagation();
            event.preventDefault()
            onCancel(previousForm, previousFormName, previousWorkflowName);
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    }

    return (
        <div className="submit nowrap">
            <button
                id={elementId}
                type="button"
                className="submit-button"
                onMouseUp={handleMouseSubmit}
                onKeyUp={handleKeySubmit}
                onKeyDown={handleKeyDown}
                ref={inputRef}
                tabIndex={tabIndex}
            >
                {title}
            </button>
            {isEdit && (
                <button
                    type="button"
                    className="cancel-button"
                    onMouseUp={handleMouseCancel}
                    onKeyUp={handleKeyCancel}
                    onKeyDown={handleKeyDown}
                    tabIndex={tabIndex + 0.1}
                >
                    Cancel
                </button>
            )}
        </div>
    );
};

export default SubmitButton;
