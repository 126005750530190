import IMessageBubble from "../interfaces/IMessageBubble";
import IMessage from "../interfaces/IMessage";
import IMessageBubbleProps from "../interfaces/IMessageBubbleProps";

import "../styles/MessageBubble.css";

import React, { MouseEvent, useEffect, useState } from "react";
import moment from "moment";

export const MessageBubble: React.FC<IMessageBubbleProps> = ({
    message,
    callbackDisplayPreviousForm,
    messageBubbleRef,
    userMessageTabIndex,
}) => {
    const { messages, userMessage, requestDate, responseDate, bubbleId } = message;
    const chatView = process.env.REACT_APP_CHAT_VIEW === "true";

    const [bubbleMessage, setBubbleMessage] = useState<IMessageBubble>({ messages: [] });

    const date = moment(userMessage ? responseDate : requestDate).format(process.env.REACT_APP_CHAT_DATE_FORMAT);

    const editForm = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        callbackDisplayPreviousForm(bubbleMessage);
    };

    useEffect(() => {
        setBubbleMessage(message);
    }, [message]);

    return (
        <div
            className={`message-bubble ${userMessage ? "user-message-bubble" : "app-message-bubble"}`}
            ref={messageBubbleRef}
        >
            <div>
                <div
                    id={`icon-${bubbleId}`}
                    className={userMessage ? "user-message-icon" : "app-message-icon"}
                />
                <div
                    className={`message-content ${userMessage ? "user-message-content" : "app-message-content"}`}
                >
                {userMessage && message.formName &&
                    <div className="message-name">{(message.formName.replace(/_/g, ' '))}</div>
                }
                    <div className="message-date">
                        <span>{chatView && date}</span>
                        {userMessage && (
                            <button
                                id={`edit-message-bubble-${messages.length}`}
                                className="message-edit"
                                onClick={editForm}
                                onInput={editForm}
                                disabled={message.disabled}
                                tabIndex={userMessageTabIndex}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                    />
                                </svg>
                            </button>
                        )}
                    </div>
                    {
                        messages.filter(messageItem => messageItem?.displayValue !== "").sort((a: IMessage, b: IMessage) => a.elementId > b.elementId ? 1 : -1).map((messageItem, index) => {
                            return (
                                <div
                                    id={`${bubbleId}_${index}`}
                                    className="message"
                                    key={`${bubbleId}_${index}`}
                                >
                                    {chatView && userMessage &&
                                        <p className="title">
                                            {messageItem.title}
                                        </p>
                                    }
                                    <p className="content" dangerouslySetInnerHTML={{ __html: messageItem.displayValue ?? messageItem.value }} />
                                </div>
                            );
                        })}
                </div>
            </div>
            {!chatView && userMessage &&
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="m9 18 6-6-6-6"></path>
                </svg>
            }
        </div>
    );
};

export default MessageBubble;
