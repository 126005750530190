import IGeneric from "../interfaces/IGeneric";
import IForm from "../interfaces/IForm";
import IJourneyResponse from "../interfaces/IJourneyResponse";
import IHeaders from "../interfaces/IHeaders";

import { sendPrinsixRequest } from "./PrinSIX";
import IMenuItem from "../interfaces/IMenuItem";

export const getJourneyResponse = async (
    updateHeaders: (result: IHeaders) => void,
    headerState: IHeaders,
    formHistory: IForm[],
    currentForm: IForm | undefined,
    formData: IGeneric | undefined,
    resumeHeader: IGeneric | undefined,
    menuItem: IMenuItem | undefined,
): Promise<IJourneyResponse> => {
    // TODO get token

    const foundForm = currentForm?.name !== undefined && currentForm?.workflow !== undefined
        ? formHistory?.find((form: IForm) => form.name === currentForm.name && form.workflow === currentForm.workflow && (form.actionId ?? 0 === currentForm.actionId ?? 0))
        : undefined;

    //const { action, contentType, method } = foundForm !== undefined ? foundForm : {} as any;

    const existingResponse: IJourneyResponse = {
        form: foundForm !== undefined ? foundForm : {} as any,
        formHistory: formHistory.filter((form: IForm) => !(
            form.name === currentForm?.name && form.workflow === currentForm?.workflow && (form.actionId ?? 0 === currentForm?.actionId ?? 0)
        )),
    };

    const { status, returnedData } = await sendPrinsixRequest(
        updateHeaders,
        headerState,
        currentForm,
        formData,
        resumeHeader,
        menuItem,
        foundForm,
    );

    return (typeof (status) == "number" && status >= 200 && status < 300)
        ? returnedData
        : existingResponse;
}
