import IComponent from "./IComponent";
import Title from "../components/Title";
import Text from "../components/Text";
import Image from "../components/Image";
import SliderInput from "../components/SliderInput";
import SubmitButton from "../components/SubmitButton";
import TextInput from "../components/TextInput";
import SingleSignOn from "../components/SingleSignOn";
import CheckboxInput from "../components/CheckboxInput";
import RadioInput from "../components/RadioInput";
import SelectInput from "../components/SelectInput";
import AddressSearch from "../components/AddressSearch";
import HiddenInput from "../components/HiddenInput";
import FileInput from "../components/FileInput";
import FileOutput from "../components/FileOutput";
import Table from "../components/Table";
import Widget from "../components/Widget";

export const IComponentList: IComponent[] = [
    { jsonName: "title", component: Title, type: "title" },
    { jsonName: "text", component: Text, type: "text" },
    { jsonName: "image", component: Image, type: "title" },
    { jsonName: "input_radio", component: RadioInput, type: "radio" },
    { jsonName: "input_button_radio", component: RadioInput, type: "button-radio" },
    { jsonName: "input_checkbox", component: CheckboxInput, type: "checkbox" },
    { jsonName: "input_submit", component: SubmitButton, type: "submit" },
    { jsonName: "input_phone", component: TextInput, type: "tel" },
    { jsonName: "address_line", component: TextInput, type: "text" },
    { jsonName: "address_city", component: TextInput, type: "text" },
    { jsonName: "address_county", component: TextInput, type: "text" },
    { jsonName: "address_postcode", component: TextInput, type: "text" },
    { jsonName: "input_text", component: TextInput, type: "text" },
    { jsonName: "input_sso", component: SingleSignOn, type: "single-sigin-on" },
    { jsonName: "input_address", component: AddressSearch, type: "address" },
    { jsonName: "input_select", component: SelectInput, type: "select" },
    { jsonName: "input_date", component: TextInput, type: "date" },
    { jsonName: "input_file", component: FileInput, type: "file" },
    { jsonName: "input_email", component: TextInput, type: "email" },
    { jsonName: "input_hidden", component: HiddenInput, type: "hidden" },
    { jsonName: "income", component: TextInput, type: "number" },
    { jsonName: "expense", component: TextInput, type: "number" },
    { jsonName: "input_number", component: TextInput, type: "number" },
    { jsonName: "input_money", component: TextInput, type: "number" },
    { jsonName: "amount_slider", component: SliderInput, type: "range" },
    { jsonName: "term_slider", component: SliderInput, type: "range" },
    { jsonName: "output_table", component: Table, type: "table" },
    { jsonName: "output_files", component: FileOutput, type: "display" },
    { jsonName: "output_files_download", component: FileOutput, type: "download" },
    { jsonName: "output_widget", component: Widget, type: "widget" },
    
    //     { ref: "input_tokenex_card", component: TokenExInput },

    //     { ref: "input_phone", component: PhoneInput },

    //     { ref: "address_line", component: TextInput },
    //     { ref: "address_city", component: TextInput },
    //     { ref: "address_county", component: TextInput },
    //     { ref: "address_postcode", component: TextInput },

    //     { ref: "input_sso", component: SingleSignOnInput },
    //     { ref: "output_widget", component: Widget },

];

export default IComponentList;
