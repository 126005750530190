import IGeneric from "../interfaces/IGeneric";

import "../styles/FileInput.css";
import Label from "./Label";

import { mutliValidator } from "../utils/validators";

import React, { useEffect, useState } from "react";

export const FileInput: React.FC = ((props: IGeneric) => {
    const { type, attributes, elementId, onStateChange, validations, onError, error, containerRef, inputRef, tabIndex } = props;

    const { value, label, tooltip, multiple, fileName, noWrap } = attributes;

    const { fileType } = validations;

    const [inputValue, setInputValue] = useState<IGeneric[]>(value ?? []);

    const validate = (selectedFiles: IGeneric[], show: boolean) => {
        const validationMessage = mutliValidator(
            selectedFiles,
            type,
            validations,
            attributes,
        );

        if (validationMessage)
            onError({ validationMessage, show });
        else {
            if (Array.isArray(selectedFiles) && !attributes.multiple)
                onStateChange(selectedFiles.length > 0 ? selectedFiles.at(0) : null);
            else
                onStateChange(selectedFiles);
            setInputValue(selectedFiles);
            onError(undefined);
        }
    };

    const readUploadedFileAsBase64 = (inputFile: File) => {
        const fileReader = new FileReader();

        return new Promise((resolve, reject) => {
            fileReader.onerror = () => {
                fileReader.abort();
                reject(new DOMException("Problem parsing input file."));
            };

            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.readAsDataURL(inputFile);
        });
    };

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        setInputValue([]);
        const selectFiles: IGeneric[] = [];
        const files = event.target.files ?? [];
        //if (files === undefined || files === null || files.length === 0) return;
        for (let file of files) {
            selectFiles.push({
                lastModified: file.lastModified,
                name: file.name,
                size: file.size,
                type: file.type,
                data: await readUploadedFileAsBase64(file),
            } as IGeneric);
        }
        validate(selectFiles, true);
    }

    const fileTypes = fileType === "Image" ? "image/png,image/gif,image/jpeg" : (fileType === "Document" ? "application/msword,application/pdf" : (fileType === "Both" ? "application/msword,application/pdf,image/png,image/gif,image/jpeg" : ".*"));

    useEffect(() => {
        validate(inputValue, false);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={`input-container file-input ${noWrap}`} id={`${elementId}_container`} ref={containerRef}>
            <div>
                <div>
                    <Label
                        elementId={elementId}
                        content={label}
                        tooltip={tooltip}
                    />
                    <input
                        key={elementId}
                        className={error && error.show ? "error" : ""}
                        id={elementId}
                        type={type}
                        accept={fileTypes}
                        multiple={multiple}
                        onChange={handleChange}
                        ref={inputRef}
                        tabIndex={tabIndex}
                    />
                    <p
                        id={`${elementId}_error`}
                        className="error"
                    >
                        {error && error.show && error.validationMessage}
                    </p>
                </div>
            </div>
        </div>
    );
});

export default FileInput;
